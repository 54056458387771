<template>
  <div>
    <a
      class="text-decoration-none text-subtitle-2 font-weight-bold"
      @click="returnBack"
    >
      <v-icon
        color="primary"
        dense
        class="mr-2"
      >
        {{ icons.mdiArrowLeft }}
      </v-icon>
      Kembali
    </a>
    <h3 class="my-8">
      Tambah Materi
    </h3>
    <v-card class="pa-4">
      <v-card-text>
        <div class="mb-4">
          <h3>Tambah Materi (Wajib diisi)</h3>
          <span>Materi akan ditambahkan ke dalam BAB</span>
        </div>
        <div>
          <v-text-field
            v-model="chapter.chapter_of"
            label="Materi ke"
            :rules="[validation.required]"
            outlined
            dense
            type="number"
          ></v-text-field>
        </div>
        <div>
          <v-text-field
            v-model="chapter.name"
            label="Judul Materi"
            :rules="[validation.required]"
            outlined
            dense
          ></v-text-field>
        </div>
        <div class="mb-4">
          <h3>Icon (Optional)</h3>
          <span>Icon untuk materi pelajaran</span>
        </div>
        <div class="d-flex mb-4">
          <v-card
            width="120"
            height="110"
            class="d-flex align-center justify-center"
            style="border: 1px dashed #959595;"
            @click="addIcon"
          >
            <div class="text-center">
              <v-icon
                v-if="!lesson.icon"
                large
              >
                {{ icons.mdiPlus }}
              </v-icon>
              <v-img
                v-else
                v-model="chapter.bank_icon_uuid"
                width="40"
                :src="lesson.icon"
                class="mx-auto"
              ></v-img>
            </div>
          </v-card>

          <div class="ml-4">
            <h4 class="font-weight-light">
              Icon akan ditampilkan di halaman materi pelajaran
            </h4>
            <h4 class="primary--text mb-10">
              Pilih Icon
            </h4>
            <small>*Icon akan dipilih secara default jika anda ingin melewatkannya</small>
          </div>
        </div>
        <h3>Isi materi (Wajib diisi)</h3>
        <p>Materi bisa berupa teks atau video</p>
        <div>
          <v-text-field
            v-model="lesson.name"
            label="Nama Materi"
            :rules="[validation.required]"
            outlined
            dense
          >
          </v-text-field>
        </div>
        <div>
          <v-text-field
            v-model="lesson.url_video"
            label="Link Video Materi (Jika Ada)"
            outlined
            dense
          >
          </v-text-field>
        </div>
        <div class="mb-5">
          <CKEditor
            v-model="lesson.content"
            label="Content"
            outlined
            dense
            @input="handleCKeditor"
          ></CKEditor>
        </div>

        <div class="d-flex">
          <v-switch
            v-model="archiveStatus"
            :label="`Masukkan Materi ke Arsip`"
            class="mt-0 me-4 customSwitch"
          ></v-switch>
          <div class="mt-1">
            <a
              class="text-decoration-none"
              @click="showFormArchiveLesson"
            ><v-icon color="#EC008C">{{ icons.mdiPlus }}</v-icon> Ambil dari Arsip Materi</a>
          </div>
        </div>
        <div>
          <h3>Komentar</h3>
          <v-switch
            v-model="status"
            :label="`Komentar ${status?'Diaktifkan': 'Dinonaktifkan'} `"
          ></v-switch>
        </div>
        <v-divider></v-divider>
      </v-card-text>
      <v-card-actions class="d-flex justify-center">
        <v-btn
          color="primary"
          large
          :disabled="!formValidChapter || !formValidLesson"
          :loading="isLoadingButton"
          @click="add()"
        >
          Simpan
        </v-btn>
        <v-btn
          color="primary"
          outlined
          large
          @click="cancelAction"
        >
          Batal
        </v-btn>
      </v-card-actions>
    </v-card>
    <ModalDialog
      :visible="modalDialog"
      :width="width"
      :hidden-actions="false"
      header="Pilih Icon"
      @close-modal="handlerButton"
    >
      <template v-slot:body>
        <h4>Icon akan ditampilkan di halaman materi pelajaran</h4>
        <p>Pilih salah satu icon:</p>
        <v-row>
          <v-col
            v-for="featureItem in features"
            :key="featureItem.uuid"
            cols="3"
          >
            <v-card
              v-model="chapter.bank_icon_uuid"
              outlined
              height="80"
              class="pa-4 elevation-0 d-flex justify-center align-center"
              clickable
              :color="cardActive == featureItem.uuid ? 'primary' : ''"
              @click="getIcon(featureItem.icon, featureItem.uuid)"
            >
              <div>
                <v-img
                  width="40"
                  :src="featureItem.icon"
                  class="mx-auto"
                ></v-img>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </template>
      <template v-slot:footer>
        <v-btn
          color="primary"
          dense
          large
          @click="chooseIcon(icon.icon, icon.uuid)"
        >
          Pilih
        </v-btn>
        <v-btn
          color="primary"
          dense
          large
          outlined
          @click="handlerButton"
        >
          Batal
        </v-btn>
      </template>
    </ModalDialog>
    <ModalDialog
      :width="778"
      :visible="archiveModalDialog"
      :hidden-actions="false"
      header="Pilih Materi"
      @close-modal="archiveModalHandlerButton"
    >
      <template v-slot:body>
        <h4>Pilih materi dari Arsip Materi yang akan anda masukkan kedalam Materi Pelajaran</h4>
        <v-row class=" pt-2">
          <v-col cols="6">
            <div>
              <v-text-field
                v-model="search"
                label="Cari Materi"
                outlined
                dense
                @change="searchHandler($event)"
              ></v-text-field>
            </div>
          </v-col>
        </v-row>
        <div class="mb-6 custom-table">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-uppercase">
                    <v-checkbox disabled></v-checkbox>
                  </th>
                  <th class="text-uppercase">
                    Icon Materi
                  </th>
                  <th class="text-uppercase">
                    Nama Materi
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(archiveLessonItem, index) in archiveLessons"
                  :key="index"
                >
                  <td>
                    <v-checkbox
                      v-model="archiveLessonToAdd"
                      :value="archiveLessonItem.uuid"
                    ></v-checkbox>
                  </td>
                  <td class=" py-2">
                    <div class="d-flex align-center ">
                      <v-img
                        class="mr-3"
                        max-width="50"
                        :src="`${archiveLessonItem.icon.icon}`"
                      ></v-img>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex align-center ">
                      <span class="">{{ archiveLessonItem.title }}</span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </template>
      <template v-slot:footer>
        <v-btn
          color="primary"
          dense
          large
          :disabled="isLoadingButton"
          :loading="isLoadingButton"
          @click="exportArchiveLesson(archiveLessonToAdd)"
        >
          Simpan
        </v-btn>
        <v-btn
          color="primary"
          dense
          outlined
          large
          @click="archiveModalDialog = false"
        >
          Batal
        </v-btn>
      </template>
    </ModalDialog>
  </div>
</template>

<script>
import { integerValidator, required } from '@core/utils/validation'
import { mdiArrowLeft, mdiPlus } from '@mdi/js'
import CKEditor from '../../components/CkEditor.vue'
import ModalDialog from '../../components/ModalDialog.vue'

export default {
  name: 'Lesson',
  components: {
    ModalDialog,
    CKEditor,
  },
  data() {
    return {
      search: '',
      archiveLessons: [],
      archiveLessonToAdd: '',
      icons: {
        mdiPlus,
        mdiArrowLeft,
      },
      chapter: {
        section_uuid: '',
        chapter_of: '',
        name: '',
        type: 'lesson',
        bank_icon_uuid: null,
      },
      validation: {
        required,
        integerValidator,
      },
      subject: {},
      chapters: [],
      lesson: {
        chapter_uuid: '',
        name: '',
        content: null,
        url_video: '',
        is_comment: 0,
        icon: '',
        is_archive: 0,
        teacher_uuid: '',
      },
      icon: {
        uuid: '',
        icon: '',
      },
      isLoadingButton: false,
      isDisabledButton: false,
      modalDialog: false,
      archiveModalDialog: false,
      width: 500,
      status: true,
      archiveStatus: false,
      features: [],
      cardActive: '',
      tempContent: '',
      tempImage: [],
      formValidLesson: false,
      formValidChapter: false,
      chapterUuid: '',
    }
  },
  watch: {
    search: {
      handler() {
        this.getArchiveLesson()
      },
    },
    lesson: {
      handler() {
        const valid = []
        const requiredField = ['name', 'content']
        Object.entries(this.lesson).forEach(([key, item]) => {
          if (requiredField.includes(key)) valid.push(!!(item && item.length))
        })
        this.formValidLesson = !valid.includes(false)
      },
      deep: true,
    },
    chapter: {
      handler() {
        const valid = []
        const requiredField = ['name', 'chapter_of', 'bank_icon_uuid']
        Object.entries(this.chapter).forEach(([key, item]) => {
          if (requiredField.includes(key)) valid.push(!!(item && item.length))
        })
        this.formValidChapter = !valid.includes(false)
      },
      deep: true,
    },
  },
  async mounted() {
    this.getTeacher()
    this.getBankIcon()
    this.getSection(this.$route.params.uuid)
  },
  methods: {
    getTeacher() {
      this.teacher = JSON.parse(localStorage.getItem('user')).user.details
      this.teacher.forEach(el => {
        if (el.teacher.uuid === undefined) {
          this.teacher_uuid = el.teacher.uuid

          return
        }
        if (el.teacher.uuid === null) {
          this.showSnackbar({
            text: "You don't have access to teacher",
            color: 'error',
          })

          return
        }
        this.teacher_uuid = el.teacher.uuid
      })

      return true
    },
    cancelAction() {
      this.modalDialog = false
      const image = this.tempContent.split('"')
      image.forEach(async item => {
        const img = item.split('/').pop()
        if (img.length === 44) {
          const fileName = {
            file: img,
          }
          await this.deleteStorage(fileName)
        }
      })
      this.tempContent = ''

      return this.$router.back()
    },
    async deleteStorage(fileName) {
      await this.$services.ApiServices.deleteStorage('lesson', fileName).then(({ data }) => true)
    },
    handleCKeditor(event) {
      this.lesson.content = event
      if (this.tempContent.length < event.length) {
        this.tempContent = event
      }
    },
    addIcon() {
      this.modalDialog = true
    },
    showFormArchiveLesson() {
      this.archiveModalDialog = true
      this.getArchiveLesson()
    },
    searchHandler(data) {
      this.search = data
    },
    getIcon(icon, uuid) {
      this.icon.icon = icon
      this.icon.uuid = uuid
      this.cardActive = uuid
    },
    chooseIcon(icon, uuid) {
      this.lesson.icon = icon
      this.chapter.bank_icon_uuid = uuid
      this.modalDialog = false
    },
    getBankIcon() {
      this.$services.ApiServices.list('bankicon').then(
        ({ data }) => {
          this.features = data.data
        },
        err => {
          console.error(err)
        },
      )
    },
    resetForm() {
      this.chapter.chapter_of = ''
      this.chapter.name = ''
      this.lesson.name = ''
      this.lesson.url_video = ''
      this.lesson.content = ''
    },
    returnBack() {
      return this.$router.back()
    },
    async getSection(uuid) {
      this.$services.ApiServices.get('section', uuid).then(({ data }) => {
        this.section = data.data
      })
    },
    async add() {
      this.isLoadingButton = true
      this.isDisabledButton = true
      this.chapter.section_uuid = this.$route.params.uuid
      this.chapterUuid = ''
      const tempImage = this.tempContent.split('"')
      tempImage.forEach(async item => {
        const img = item.split('/').pop()
        if (img.length === 44) {
          this.tempImage.push(img)
        }
      })
      const image = this.lesson.content.split('"')
      image.forEach(item => {
        const img = item.split('/').pop()
        if (img.length === 44) {
          const carIndex = this.tempImage.indexOf(img)
          this.tempImage.splice(carIndex, 1)

          this.tempImage.forEach(async item => {
            await this.deleteStorage({ file: item })
          })
        }
      })

      if (
        // eslint-disable-next-line operator-linebreak
        this.chapter.chapter_of === '' ||
        // eslint-disable-next-line operator-linebreak
        this.chapter.name === '' ||
        // eslint-disable-next-line operator-linebreak
        this.lesson.name === '' ||
        // eslint-disable-next-line operator-linebreak
        this.lesson.content === ''
      ) {
        this.showSnackbar({
          text: 'field tidak boleh ada yang kosong!!',
          color: 'error',
        })

        return
      }

      await this.$services.ApiServices.add('chapter', this.chapter).then(
        ({ data }) => {
          this.showSnackbar({
            text: 'Add Lesson Has Successfully',
            color: 'success',
          })
          this.chapterUuid = data.data.uuid
          this.chapter.name = data.data.name
        },
        err => {
          this.isLoadingButton = false
          this.isDisabledButton = false
          console.error(err)
        },
      )

      await this.addLesson()

      this.isLoadingButton = false
      this.isDisabledButton = false
      this.resetForm()
      this.returnBack()
    },

    async addLesson() {
      this.lesson.chapter_uuid = this.chapterUuid
      this.lesson.is_comment = this.status ? 1 : 0
      this.lesson.is_archive = this.archiveStatus ? 1 : 0
      this.lesson.teacher_uuid = this.teacher_uuid
      this.lesson.name = this.chapter.name
      this.lesson.icon = this.chapter.bank_icon_uuid
      await this.$services.ApiServices.add('lesson', this.lesson).then(
        ({ data }) => {},
        err => {
          console.error(err)
          this.isLoadingButton = false
        },
      )
    },

    exportArchiveLesson(uuid) {
      this.archiveModalDialog = false
      this.$services.ApiServices.get('archivelesson', uuid).then(
        ({ data }) => {
          this.lesson = data.data
          this.chapter.name = data.data.title
          this.lesson.is_archive = this.archiveStatus
          this.lesson.name = data.data.title
          this.icon = data.data.icon.icon
          this.chapter.bank_icon_uuid = data.data.icon.uuid
          this.cardActive = this.chapter.bank_icon_uuid
        },
        err => console.error(err),
      )
    },
    async getArchiveLesson(params = {}) {
      await this.$services.ApiServices.list('archivelesson', {
        ...params,
        search: this.search,
        // search: this.search,
        per_page: 'all',
        teacher_uuid: this.teacher_uuid,
      }).then(
        ({ data }) => {
          this.archiveLessons = data.data
          this.isEmpty = false
          if (this.archiveLessons.length < 1) {
            this.isEmpty = true
          }
          this.totalPages = data.meta.last_page
        },
        err => {
          console.error(err)
        },
      )
      this.isLoadingCard = false
    },
    handlerButton() {
      this.lesson.icon = null
      this.chapter.bank_icon_uuid = null
      this.modalDialog = false
      this.cardActive = ''
    },
    archiveModalHandlerButton() {
      this.archiveModalDialog = false
    },
    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },
  },
}
</script>

<style scoped>
.v-application--is-ltr .v-input--selection-controls__input {
  margin-right: 15px !important;
}
.clickable {
  cursor: pointer;
}
.border-none {
  border: none;
}
.custom-table {
  height: 100%;
  max-height: 200px;
  overflow-y: auto;
}
</style>
